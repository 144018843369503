.position_relative {
  position: relative;
}

.password-nor-letters-info-container {
  @include respond-up-to(lg) {
    margin-top: 3px;
  }

  @include respond-to(lg) {
    position: absolute;
    bottom: -85px;
    left: 320px;
    height: 200px;
    display: flex;
    align-items: center;
  }

  .password-nor-letters-info-arrow {
    @include respond-up-to(lg) {
      margin: 0 auto;
      width: 0;
      height: 0;
      border-left: 21px solid transparent;
      border-right: 21px solid transparent;
      border-bottom: 21px solid $lightBlue;
    }

    @include respond-to(lg) {
      width: 0;
      height: 0;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-right: 20px solid $lightBlue;
    }
  }
}

.password-nor-letters-info-box {
  display: flex;
  padding: 1.2rem;
  background-color: $lightBlue;
  justify-content: space-between;

  @include respond-to(lg) {
    width: 300px;
  }

  &__info-icon {
    fill: $white;
    flex-basis: 34px;
    flex-shrink: 0;
  }

  &__text {
    color: $white;
    font-size: 1.4rem;
  }

  &__link {
    color: $white;
    font-size: inherit;
    text-decoration: underline;
  }

  &__close-icon {
    flex-shrink: 0;
    padding-left: 10px;

    button {
      display: inline-block;
      border: none;
      padding: 0;
      margin: 0;
      text-decoration: none;
      background: $lightBlue;
      color: $white;
      font-family: inherit;
      font-size: 100%;
      cursor: pointer;
      text-align: center;
      transition: background 250ms ease-in-out, transform 150ms ease;
      -webkit-appearance: none;
      -moz-appearance: none;

      svg {
        fill: $white;
      }
    }

    button:hover,
    button:focus {
      background: rgb(16, 55, 95);
    }

    button:focus {
      outline: 1px solid #fff;
      outline-offset: 0px;
    }

    button:active {
      transform: scale(0.95);
    }
  }
}
