@import '../variables';

.popup-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
  padding: 1rem;
  z-index: 100;
}

.popup-wrapper {
  @include respond-to(md) {
    display: block;
    position: relative;
    padding: 3rem;
    top: 18rem;
    max-width: 35rem;
    margin: 0 auto;
  }
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $white;
  padding: 2rem;
  text-align: left;
  border-radius: 0.3rem;
  border: 1px solid $borderColor;
}

.popup {
  margin: 0 auto;
  width: 90%;
  @include respond-to(md) {
    width: auto;
  }
}

.popup-header {
  font-size: $contentHeaderSize;
  font-weight: $contentHeaderWeight;
}

.popup-text {
  margin-top: 1rem;
}

.popup-buttonContainer {
  display: flex;
  justify-content: flex-end;
  & button {
    width: 8rem;
    margin-left: 1.5rem;

    @include respond-to(md) {
      margin-left: 0;
    }
  }
}

.popup-imageContainer {
  padding-top: 2rem;
  text-align: center;
}