@import '../variables';

.line {
  margin-top: 0;
  position: relative;
  width: 100%;
  height: 1.22rem;
  @include respond-to(sm) {
    position: static;
    margin-top: 1rem;
    z-index: auto;
    height: 1.25rem;
  }
}

.line-text {
  font-size: 1.4rem;
  text-align: center;
}
