@import '../variables.scss';

.page-inner {
  background: $contentBg;
  margin: 0 auto;
  display: inline-block;
  padding: $contentPadding;
  min-width: 30rem;
  @include respond-up-to(sm) {
    padding: 2.4rem;
  }
  @include respond-up-to(sm) {
    display: block;
    padding: 0 $horizontalMarginSmall;
  }
}

.page-outer {
  text-align: center;
  padding: 5rem 0 7.5rem 0;
  @include respond-up-to(sm) {
    padding: 0 0 2rem 0;
    background: $contentBg;
  }
  width: 100%;
  flex-grow: 1;
}

.page {
  text-align: left;
  min-width: $formWidth;
  max-width: $formWidth;
  @include respond-up-to(sm) {
    display: block;
    max-width: $contentWidthSmall;
    min-width: $contentWidthSmall;
    margin: 0 auto;
  }
  &--wide {
    min-width: $formWidthWide;
    max-width: $formWidthWide;
  }
  &__header {
    font-size: $contentHeaderSize;
    font-weight: normal;
    margin-bottom: $formVerticalMargin;
    @include respond-up-to(sm) {
      margin-top: $formVerticalMargin;
    }
  }
  &__info-text {
    max-width: 28rem;
    margin-top: 1rem;
    font-size: 1.6rem;
    a {
      font-size: inherit;
    }
  }
}

.subSection {
  margin-top: 3rem;
  border-top-color: $lightGray;
  border-top-style: solid;
  border-top-width: 0.15rem;
}

.sub-header {
  font-size: $contentSubHeaderSize;
  margin-top: 2rem;
  font-weight: normal;
}

.centered {
  display: block;
  text-align: center;
}


.listLink {
  margin-bottom: $formVerticalMargin;
  & a {
    font-size: 1.6rem;
    display: block;
    margin-top: 1rem;
    color: $linkColor;
  }
}

.error-text {
  margin-top: 1rem;
  color: red;
  font-size: 1.4rem;
  a {
    font-size: inherit;
  }
}