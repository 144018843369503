@import '../variables.scss';

.eyeIcon {
  width: 2.5rem;
  position: absolute;
  top: 0.4rem;
  left: calc(100% - 3rem);
  fill: #BBBBBB;
  @include respond-to(sm) {
    display: none;
  }
}

.eyeIconActive {
    fill: #444444;
}

.eyeIconError {
  top: 3rem;
}