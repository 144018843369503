html {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 62.5%;
  line-height: 1.5;
}

h1 {
  font-size: 3.6rem;
  line-height: 3.9rem;
  &:first-letter {
    text-transform: capitalize;
  }
}

h2 {
  margin: 0;
  font-size: 2rem;
  line-height: 2.9rem;
  font-weight: normal;
}

p {
  font-size: 1.6rem;
}
