@import "../variables.scss";

@mixin button-defaults {
  padding: 0.6rem 2rem;
  position: relative;
  border: none;
  margin-top: $formVerticalMargin;
  width: 100%;
  margin-right: 0;
  cursor: pointer;
  font-size: 1.8rem;
  line-height: 2.6rem;
  border-radius: 0.3rem;
  align-items: center;
  display: flex;

  @include respond-to(md) {
    margin-right: 2.5rem;
    width: auto;
  }

  &:last-child {
    margin-right: 0;
  }

  //@include respond-to(md) {
  //  &:only-child {
  //    margin-top: 0;
  //  }
  //}
}

.button-group {
  display: flex;

  @include respond-up-to(sm) {
    flex-direction: column-reverse;
  }

  @include respond-to(md) {
    flex-direction: row;
    justify-content: space-between;
  }

  &--single {
    @include respond-to(md) {
      flex-direction: row;
      justify-content: flex-end;
    }

    //margin-top: 2rem;
  }
}

.primaryButton {
  @include button-defaults;
  color: #fff;
  background: #222;
  justify-content: center;

  &:hover,
  &:focus {
    background: #444;
    text-decoration: none;
  }

  &:disabled {
    background: $disabledColor;
    cursor: default;
  }
}

.secondaryButton {
  @include button-defaults;
  background: $white;
  justify-content: center;
  border: 1px solid #bbb;
  color: $lightBlue;

  &:hover,
  &:focus {
    background: #ededed;
    text-decoration: none;
  }
}

.chooseUserButton {
  @include button-defaults;
  background: $white;
  border: 1px solid $lightBlack;
  color: $lightBlack;
  justify-content: space-between;

  &:hover,
  &:focus {
    background: #ededed;
    text-decoration: none;
  }
}

.linkTypeButton {
  color: $linkColor;
}

.buttonWebKit {
  padding: 0.7rem 2rem;
  line-height: normal;
}

.buttonFirefoxWin {
  padding-top: 0.4rem;
}

.buttonFirefoxLinux {
  padding-bottom: 0.4rem;
}

.spinner {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  height: 2.6rem;
}

.buttonText {
  font-family: Arial, Helvetica, sans-serif;
}

.buttonWide {
  width: 100%;
}

.idportenLogo {
  background: url("../images/idporten-logo.svg") no-repeat left 0rem top 50%;
  width: 2rem;
  height: 2rem;
  margin-right: 1.5rem;
  background-size: contain;
}

.arrowRight {
  width: 2rem;

  svg {
    width: 2rem;
    fill: $lightBlack;
    vertical-align: sub;
  }
}

.hideText {
  color: #222;
}

.fetching {
  &:disabled {
    background: #222;
  }
}

.noMargin {
  margin: 0;
}