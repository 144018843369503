@import '../variables.scss';

.username-list {
  margin-top: $formVerticalMargin;

  &__list-item {
    font-size: 1.5rem;
    list-style-type: none;
    background: url('../images/bullet-li-grey.png') no-repeat left 0.7rem;
    background-size: 0.6rem;
    background-origin: padding-box;
    border-left: 0.2rem solid $contentBg;
    padding-left: 1.5rem;

    &__link {
      text-decoration: none;
      color: $linkColor;
      margin-left: 1rem;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
