$breakpoints: (
  sm: 500px,
  md: 725px,
  lg: 1001px
);

@mixin respond-to($size) {
  @media only screen and (min-width: map-get($breakpoints, $size)) {
    @content;
  }
}


@mixin respond-between($min, $max) {
  @media only screen and (min-width: map-get($breakpoints, $min)) and (max-width: map-get($breakpoints, $max)) {
    @content;
  }
}

@mixin respond-up-to($size) {
  @media only screen and (max-width: map-get($breakpoints, $size)) {
    @content;
  }
}

$black: #000000;
$gray: #555555;
$lightGray: #cacaca;
$darkGrey: #58585b;
$darkTextColor: #222222;
$lightBlack: #2D2D2E;
$lightBlue: #2771BB;
$white: #FFFFFF;
$yellow: #ffbe2e;
$darkYellow: #eca200;
$lightYellow: #fff0ce;
$darkRed: #db2c66;
$errorRed: #CC0000;
$deepRed: #BB0000;
$pink: #ffd6d9;
$lightPink: #fff2f2;
$green: #008000;
$honeyDew: #F0FFF0;
$darkGreen: #006400;

$globalMaxWidth: 97rem;

$headerLogoBarBg: $black;
$headerMainAreaBg: $lightBlack;
$headerMainAreaVertPadding: 2rem;
$headerDescColor: $white;

$contentWrapperPaddingTop: 5rem;
$contentWrapperPaddingBtm: 7.5rem;
$contentPadding: 3.6rem;
$contentBg: #FFF;
$contentHeaderSize: 2.4rem;
$contentSubHeaderSize: 2rem;
$contentHeaderWeight: normal;
$contentWidthSmall: 30rem;

$disabledColor: #E3E3E3;

$footerPaddingTop: 3rem;
$footerTextColor: $white;
$footerTextColorInverted: $black;
$footerBg: $black;
$footerBgInverted: $white;

$horizontalMargin: 6.4rem;
$horizontalMarginSmall: 1rem;

$borderColor: #ccc;
$formVerticalMargin: 2rem;
$formWidth: 31.4rem;
$formWidthWide: 36.4rem;

$linkColor: $lightBlue;
$linkFontSize: 1.5rem;

$messagePaddingLeft: 1.5rem;
