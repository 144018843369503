@import '../variables.scss';

.otp-header {
  font-size: $contentHeaderSize;
}

.otp-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.otp-change-status-info {
  font-size: 1.6rem;
  color: $darkTextColor;
  padding-top: 1rem;
  padding-left: 0.5rem;
}

.otp-status-on,
.otp-status-off {
  font-size: 1.8rem;

  svg,
  span {
    vertical-align: middle
  }
}

.otp-status-on {
  color: darkgreen;
}

.otp-status-off {
  color: $deepRed;
}