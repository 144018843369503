@import '../variables.scss';

.message-list {
  margin-top: 1rem;
  &--align-left {
    text-align: left;
  }
}

.message-list__item {
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: $darkTextColor;
  max-width: $formWidth;
  &--ordered {
    list-style-position: inside;
    margin-bottom: 1.5rem;
  }
  &--unordered {
    border-left: 0.2rem solid $contentBg;
    padding-left: $messagePaddingLeft;
    list-style-type: none;
    background: url('../images/bullet-li-grey.png') no-repeat left 0.8rem;
    background-size: 0.6rem;
    background-origin: padding-box;
  }
  &__link {
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: $linkColor;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
