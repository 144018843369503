@import './variables.scss';

/* Additional global adjustments not covered by normalize.css */

html {
  box-sizing: border-box;
}

html, body {
  border: 0;
  padding: 0;
  margin: 0;
  min-height: 100%;
  height: 100%;
}

div, span, object, iframe, img, table, caption, thead, tbody,
tfoot, tr, tr, td, article, aside, details, figure, hgroup, menu,
nav, footer, header, section, summary, mark, audio, video, ul, ol, h1, p {
  border: 0;
  margin: 0;
  padding: 0;
  font: inherit;
  vertical-align: baseline;
}

a {
  text-decoration: none;
  font-size: $linkFontSize;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

form {
  margin-bottom: $formVerticalMargin;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
