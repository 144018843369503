@import '../variables.scss';

.text-input {
  max-width: $formWidth;
  min-width: auto;
  position: relative;

  &__input {
    display: block;
    padding: 0.6rem 0.8rem;
    border: 1px solid $borderColor;
    font-size: 1.5rem;
    line-height: 1.8rem;
    margin-top: 0.5rem;
    width: 100%;

    outline: none;
    -webkit-appearance: none;
    background-image: none;

    &:invalid {
      box-shadow: none;
    }

    &:disabled {
      background-color: $disabledColor;
    }

    &--error {
      border-color: $errorRed;
      color: $errorRed;
    }
  }

  &__error-text {
    color: $errorRed;
    margin-top: 0.5rem;
    max-width: 28rem;
    font-size: 1.4rem;

    a {
      font-size: inherit;
    }
  }

  &__number {
    font-size: 2rem;
    letter-spacing: 1rem;
    text-align: center
  }
}

.text-input-label {
  display: flex;
  justify-content: space-between;

  &__label {
    margin-top: $formVerticalMargin;
    display: block;
    font-size: 1.6rem;
    color: $gray;
    user-select: none;

    @include respond-to(sm) {
      font-size: 2rem;
    }

    &--small {
      font-size: 1.3rem;
      margin-top: 0.5rem;
    }
  }

  &__req {
    color: $errorRed;
  }

  &__toggle-visibility {
    margin-top: $formVerticalMargin;
    font-size: $linkFontSize;
    text-decoration: none;
    color: $linkColor;
    user-select: none;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    display: none;
    align-items: center;

    @include respond-to(sm) {
      display: flex;
    }
  }
}
