@import '../variables.scss';

.link-list {
  margin-bottom: $formVerticalMargin;
  & a {
    font-size: 1.6rem;
    display: block;
    margin-top: 1rem;
    color: $linkColor;
  }
}

.go-back-list {
  margin-bottom: 0.5rem;
  & a {
    width: fit-content;
    display: flex;
  }
}