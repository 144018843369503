@import '../variables';

$dropDownWidth: 9rem;
$dropDownListWidth: 28rem;
$dropDownListMaxHeight: 30.2rem;

.dropdown {
  position: relative;
  width: $dropDownWidth;
  font-size: 1.4rem;
  color: #555555;
  background: $white;
  margin-right: 1rem;
  &:hover {
    cursor: pointer;
  }
  &--list {
    position: absolute;
    top: 6rem;
    border-radius: 0.3rem;;
    list-style-type: none;
    width: $dropDownListWidth;
    max-height: $dropDownListMaxHeight;
    z-index: 100;
    background: $white;
    border: 0.1rem solid $borderColor;
    &-scroll {
      overflow-y: scroll;
    }
    &--item {
      border-bottom: 0.1rem solid #C3C3C3;
      padding: 0.7rem 1.3rem;
      min-height: 3rem;
      &:hover {
        background: $lightGray;
        cursor: pointer;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.dropdownarrow--polygon {
  fill: red;
}

.text-input .dropdown__input {
  background: url('../images/dropdownarrow.svg') no-repeat right 9px top 50%;
  background-size: 14px 9px;
}