@import 'variables';
@import 'reset';
@import 'typography';
@import 'components/button';
@import 'components/dropdown';
@import 'components/fieldgroup';
@import 'components/icons';
@import 'components/line';
@import 'components/linklist';
@import 'components/messages';
@import 'components/page';
@import 'components/popup';
@import 'components/textinput';
@import 'components/usernamelist';
@import 'components/norwegianlettersinfo';
@import 'components/gobacklink.scss';
@import 'components/warning.scss';
@import 'components/infobox.scss';

.recaptcha {
  margin-top: $formVerticalMargin;
}

.fade-in-and-out-enter {
  opacity: 0.01;
}

.fade-in-and-out-enter.fade-in-and-out-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.fade-in-and-out-leave {
  opacity: 1;
}

.fade-in-and-out-leave.fade-in-and-out-leave-active {
  opacity: 0.01;
  transition: opacity 200ms ease-in;
}
