@import "../variables";

.warning {
  display: flex;
  border-radius: 4px;
  border: solid 2px;
  padding: 1.5rem;
  padding-left: 1rem;
  p {
    font-family: Arial;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #4d4d4d;
  }
  a {
    font-family: Arial;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
  }
  div {
    margin-right: 1rem;
  }
  svg {
    width: 2rem;
  }
}

.formWarning {
  margin-top: 1rem;
}

.yellowWarning {
  border-color: $yellow;
  background-color: $lightYellow;
  svg {
    fill: $darkYellow;
  }
}

.redWarning {
  border-color: $pink;
  background-color: $lightPink;
  svg {
    fill: $darkRed;
  }
}
